import React from 'react';
import './Specifications.sass';

function Specifications({ title, specifications }) {
  if (specifications.length === 0) return null;

  return (
    <div className="Specifications container">
      <div className="row">
        <div className="col-xs-12" style={{ width: '100%' }}>
          <h2>{`${title} Specs`}</h2>
          <table>
            <tbody>
              {specifications.map(spec => {
                return (
                  <tr key={spec.id}>
                    <td>{spec.name}</td>
                    <td>{spec.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="mobile-list">
            {specifications.map(spec => {
              return (
                <div key={spec.id}>
                  <h3>{spec.name}</h3>
                  <p>{spec.value}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Specifications;

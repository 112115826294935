import React from 'react';
import { Query } from 'react-apollo';
import { getContentQuery } from '../queries';
import './About.sass';

function About() {
  return (
    <section id="about" className="AboutSection">
      <Query query={getContentQuery} variables={{ name: 'about' }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return null;
          if (!data || !data.allygnContent) return null;

          return (
            <div className="container allygn-container">
              <div className="row">
                <div className="col-xs-12 col-sm-8 col-md-6">
                  <p dangerouslySetInnerHTML={{ __html: data.allygnContent.text }} />
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    </section>
  );
}

export default About;

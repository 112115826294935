import React, { useEffect } from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';
import logo from '../images/allygn-nav-logo-white.svg';
import { setNavBarCollapseBehavior } from '../utils/jQueryFunctions';
import './Nav.sass';

function Nav({ showImmediately, onIndex }) {
  useEffect(() => {
    if (showImmediately) return;
    setNavBarCollapseBehavior();
  });

  const navbarClasses = [
    'navbar',
    'navbar-expand-lg',
    'navbar-light',
    'fixed-top',
    showImmediately && 'navbar-shrink',
  ].filter(Boolean);

  return (
    <nav id="main-nav" className={navbarClasses.join(' ')}>
      <div className="container">
        <Link smooth={onIndex} to={onIndex ? '#header' : '/'} className="js-scroll-trigger nav-brand">
          <img alt="logo" height="20" src={logo} />
        </Link>
        <div className="icons-wrapper text-center">
          <a href="https://www.instagram.com/allygn_components/" rel="noopener noreferrer" target="_blank">
            <div className="icon-button">
              <i className="fa fa-instagram"></i>
            </div>
          </a>
          <a href="https://shop.fern-fahrraeder.de/" rel="noopener noreferrer" target="_blank">
            <div className="icon-button">
              <i className="fa fa-shopping-cart"></i>
            </div>
          </a>
        </div>
        <button className="navbar-toggler navbar-toggler-right" data-target="#navbarResponsive" data-toggle="collapse" type="button">
          <i className="fa fa-bars"></i>
        </button>
        <div id="navbarResponsive" className="collapse navbar-collapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link
                smooth={onIndex}
                to={`${onIndex ? '#' : '/#'}products`}
                data-target="#products"
                className="js-scroll-trigger nav-link"
              >Products</Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={onIndex}
                to={`${onIndex ? '#' : '/#'}about`}
                data-target="#about"
                className="js-scroll-trigger nav-link"
              >About</Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={onIndex}
                to={`${onIndex ? '#' : '/#'}contact`}
                data-target="#contact"
                className="js-scroll-trigger nav-link"
              >Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;

import React, { useEffect } from 'react';
import { setScrollSpy } from '../utils/jQueryFunctions';
import About from '../components/About';
import Contact from '../components/Contact';
import Header from '../components/Header';
import Nav from '../components/Nav';
import Products from '../containers/Products';
import Footer from '../components/Footer';

function IndexPage() {
  useEffect(() => setScrollSpy());

  return (
    <>
      <Nav onIndex />
      <Header />
      <Products />
      <About />
      <Contact />
      <Footer onIndex />
    </>
  );
}

export default IndexPage;

import gql from 'graphql-tag';

export const getProductsQuery = gql`
  query Products {
    allAllygnProducts {
      id
      title
      subtitle
      truncatedDescription
      firstImageUrl
      slug
      comingSoon
    }
  }
`;

export const getProductQuery = gql`
  query Product($slug: String!) {
    allygnProduct(slug: $slug) {
      id
      title
      slug
      subtitle
      description
      price
      shopUrl
      headerImageUrl
      comingSoon
      images {
        id
        name
        url
        alt
        description
        position
      }
      specifications {
        id
        name
        value
      }
      features {
        id
        title
        subtitle
        description
        mainImageUrl
        images {
          id
          name
          url
          alt
          description
          position
        }
      }
      manualDocument {
        fileUrl
      }
    }
  }
`;

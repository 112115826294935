import React from 'react';
import './BuyButton.sass'

function BuyButton({ shopUrl, onBottom, comingSoon }) {
  if (comingSoon) {
    return (
      <button className={`DisabledButton btn${onBottom ? ' onBottom' : ''}`} disabled={true}>
        COMING SOON
      </button>
    );
  }

  return (
    <a
      className={`ShopButton btn${onBottom ? ' onBottom' : ''}`}
      href={shopUrl && shopUrl !== "" ? shopUrl : '#'}
      rel="noopener noreferrer"
      target="_blank"
    >
      BUY NOW AT THE FERN BICYCLES WEBSHOP
    </a>
  );
}

export default BuyButton;

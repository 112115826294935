import React from 'react';
import './ProductsSkeleton.sass';

function ProductsSkeleton() {
  return (
    <div className="ProductsSkeleton row">
      {[...Array(6)].map((_, i) => {
        return <div key={i} className="col-xs-12 col-sm-6 col-md-4">
          <div className="item" />
        </div>
      })}
    </div>
  );
}

export default ProductsSkeleton;

import React from 'react';
import Description from './Description';
import Specifications from './Specifications';
import Features from './Features';
import BuyButton from './BuyButton';
import './Product.sass';

function Product({ allygnProduct }) {
  const { headerImageUrl } = allygnProduct;
  return (
    <div className="Product">
      <div className="product-header container-fluid">
        <div className="xs" style={{ backgroundImage: `url(${headerImageUrl}?size=xs)` }} />
        <div className="sm" style={{ backgroundImage: `url(${headerImageUrl}?size=sm)` }} />
        <div className="md" style={{ backgroundImage: `url(${headerImageUrl}?size=md)` }} />
        <div className="lg" style={{ backgroundImage: `url(${headerImageUrl}?size=lg)` }} />
        <div className="xl" style={{ backgroundImage: `url(${headerImageUrl})` }} />
      </div>
      <Description allygnProduct={allygnProduct} />
      {allygnProduct.manualDocument &&
        <div className="download-button-wrapper">
          <a
            className="download-button"
            href={allygnProduct.manualDocument.fileUrl}
            target='_blank'
            rel="noopener noreferrer"
          >
            Download manual
          </a>
        </div>
      }
      <Specifications
        title={allygnProduct.title}
        specifications={allygnProduct.specifications}
      />
      <Features
        title={allygnProduct.title}
        features={allygnProduct.features}
      />
      <div className="allygn-container">
        <BuyButton
          onBottom
          shopUrl={allygnProduct.shopUrl}
          comingSoon={allygnProduct.comingSoon}
        />
      </div>
    </div>
  );
}

export default React.memo(Product);

import $ from 'jquery';
import 'jquery.easing';
import 'bootstrap/dist/js/bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.sass';

$(function () {
  ReactDOM.render(<App />, document.getElementById('root'));
});

import React from 'react';
import Slider from 'react-slick';
import './FeatureImages.sass';

function FeatureImages({ images }) {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="FeatureImages">
      <Slider {...settings}>
        {images.map((img, index) => {
          return (
            <div key={index}>
              <img src={img.url} alt={img.url} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

function CustomPrevArrow({ className, style, onClick }) {
  return (
    <div
      className={`PrevArrow ${className}`}
      style={{ ...style, display: "block", left: "10px", zIndex: "100" }}
      onClick={onClick}
    />
  );
}

function CustomNextArrow({ className, style, onClick }) {
  return (
    <div
      className={`NextArrow ${className}`}
      style={style}
      onClick={onClick}
    />
  );
}

export default FeatureImages;

import React from 'react';
import FeatureImages from './FeatureImages';

function Feature({ feature, isVisible }) {
  if (!isVisible) return null;

  return (
    <div style={{ width: '100%' }}>
      <h3>{feature.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: feature.description }} />
      <FeatureImages images={feature.images} />
    </div>
  );
}

export default Feature;

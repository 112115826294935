import React from 'react';
import './ProductSkeleton.sass';

function ProductSkeleton() {
  return (
    <div className="ProductSkeleton">
      <div className="header container-fluid" />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="h1" />
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="main-image" />
            <div className="row thumbs">
              {[...Array(4)].map((_, i) => {
                return <div key={i} className="thumb col-xs-6 col-sm-4 col-md-3">
                  <div className="image" />
                </div>
              })}
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="description">
              {[...Array(13)].map((_, i) => {
                const width = Math.floor(Math.random() * Math.floor(8));
                return <div
                  key={i}
                  style={{ width: `${(width + 2) * 10}%` }}
                  className="text"
                />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductSkeleton;

import React, { useEffect } from 'react';
import ProductItem from './ProductItem';
import { setRevealAnimations } from '../../utils/jQueryFunctions';

function ProductsItem({ products }) {
  useEffect(() => {
    setRevealAnimations();
  });

  return (
    <div className="row">
      {products.map(p => <ProductItem key={p.id} product={p} />)}
    </div>
  );
}

export default ProductsItem;

import React from 'react';
import { Query } from 'react-apollo';
import { getProductsQuery } from '../queries';
import ProductItems from './ProductItems';
import ProductsSkeleton from './ProductsSkeleton';

function ProductsQuery() {
  return (
    <Query query={getProductsQuery}>
      {({ loading, error, data }) => {
        if (loading) return <ProductsSkeleton />;
        if (error) return null;

        return (
          <div className="direction-reveal direction-reveal--grid-bootstrap">
            <ProductItems products={data.allAllygnProducts} />
          </div>
        );
      }}
    </Query>
  );
}

export default ProductsQuery;

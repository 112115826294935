import React from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';
import CookieConsent from 'react-cookie-consent';
import logo from '../images/allygn-nav-logo-white.svg';
import './Footer.sass'

function Footer({ onIndex, onLegal }) {
  return (
    <footer className="Footer">
      <img src={logo} alt="allygn-logo" />
      <div className="container-fluid allygn-container">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <h3>Contact</h3>
            <p>
              Allygn / Fern Fahrräder
              <br/>
              Josef-Orlopp-Str. 54
              <br/>
              10365 Berlin
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <h3>Sitemap</h3>
            <p>
              <Link
                smooth={onIndex}
                to={`${onIndex ? '#' : '/#'}products`}
                data-target="#products"
              >Products</Link>
              <br/>
              <Link
                smooth={onIndex}
                to={`${onIndex ? '#' : '/#'}about`}
                data-target="#about"
              >About</Link>
              <br/>
              <Link
                smooth={onIndex}
                to={`${onIndex ? '#' : '/#'}contact`}
                data-target="#contact"
              >Contact</Link>
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <h3>Legals</h3>
            <p>
              <Link
                smooth={onLegal}
                to={`${onLegal ? '#' : '/legal#'}terms`}
                data-target="#agbs"
              >Terms</Link>
              <br/>
              <Link
                smooth={onLegal}
                to={`${onLegal ? '#' : '/legal#'}privacy`}
                data-target="#privacy"
              >Privacy</Link>
              <br/>
              <Link
                smooth={onLegal}
                to={`${onLegal ? '#' : '/legal#'}imprint`}
                data-target="#imprint"
              >Imprint</Link>
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <h3>Follow us</h3>
            <p>
              <a href="https://www.instagram.com/allygn_components/" rel="noopener noreferrer" target="_blank">
                <i className="fa fa-instagram"></i>{' '}
                Instagram
              </a>
              <br/>
              <a href="https://shop.fern-fahrraeder.de/" rel="noopener noreferrer" target="_blank">
                <i className="fa fa-shopping-cart"></i>{' '}
                Onlineshop
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid copyright text-center">
        <i className="fa fa-bicycle"></i>{' '}
        made with
        {' '}<i className="fa fa-heart"></i>{' '}
        and
        {' '}<i className="fa fa-coffee"></i>{' '}
        in Berlin
      </div>
      <CookieConsent
        disableStyles={true}
        containerClasses="cookie-consent"
        cookieName="allygn-accepted-cookie"
        buttonText="OK"
      >
        <span>
          Diese Webseite benutzt Cookies. Wenn Du die Webseite weiter nutzt, gehen wir von Deinem Einverständnis aus.
        </span>
        <span>
          <Link
            smooth={onLegal}
            to={`${onLegal ? '#' : '/legal#'}privacy`}
            data-target="#privacy"
          >Mehr erfahren</Link>
        </span>
      </CookieConsent>
    </footer>
  );
}

export default Footer;

import React from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Analytics from 'react-router-ga';
import ScrollToTop from './components/ScrollToTop';
import IndexPage from './pages/IndexPage';
import ProductPage from './pages/ProductPage';
import LegalsPage from './pages/LegalsPage';
import {
  rootRoute,
  productsRoute,
  legalsRoute,
} from './routes';

function App() {
  return (
    <ApolloProvider client={new ApolloClient({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT })}>
      <Router>
        <Analytics id={process.env.REACT_APP_GA_TRACKING_ID}>
          <ScrollToTop>
            <Route exact path={rootRoute()} component={IndexPage} />
            <Route exact path={productsRoute()} component={() => <Redirect to={rootRoute()} />} />
            <Route path={`${productsRoute()}/:slug`} component={ProductPage} />
            <Route exact path={legalsRoute()} component={LegalsPage} />
          </ScrollToTop>
        </Analytics>
      </Router>
    </ApolloProvider>
  );
}

export default App;

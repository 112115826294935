import $ from 'jquery';
import DirectionReveal from 'direction-reveal';
const navbarHeight = 54;

export function setScrollSpy() {
  // Activate scrollspy to add active class to navbar items on scroll
  $('body').scrollspy({ target: '#main-nav', offset: navbarHeight });
};

export function setNavBarCollapseBehavior() {
  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll-trigger').click(function() {
    $('.navbar-collapse').collapse('hide');
  });

  // Collapse Navbar
  const navbarCollapse = function() {
    const header = $('header#header');

    if (header.length === 0) {
      $("#main-nav").addClass("navbar-shrink");
      return;
    }

    const height = header.height() - (navbarHeight * 2);
    if ($("#main-nav").offset() && $("#main-nav").offset().top > height) {
      $("#main-nav").addClass("navbar-shrink");
    } else {
      $("#main-nav").removeClass("navbar-shrink");
    }
  };

  navbarCollapse(); // Collapse now if page is not at top
  $(window).scroll(navbarCollapse); // Collapse the navbar when page is scrolled
};

export function setRevealAnimations() {
  $('.direction-reveal a').each(function() {
    $(this).removeClass("slide--in-top slide--in-right slide--in-bottom slide--in-left");
    $(this).on('click', function(){
      $(this).removeClass("slide--in-top slide--in-right slide--in-bottom slide--in-left slide--out-top slide--out-right slide--out-bottom slide--out-left");
    });
  });

  DirectionReveal({
    selector: '.direction-reveal',
    itemSelector: '.direction-reveal__card',
    animationName: 'slide',
    enableTouch: true,
    touchThreshold: 250,
  });
};

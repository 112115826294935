import React from 'react';
import { Query } from 'react-apollo';
import { getContentQuery } from '../queries';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import './LegalsPage.sass';

function LegalsPage() {
  return (
    <>
      <Nav showImmediately />
      <section className="LegalSection" id="terms" style={{ backgroundColor: '#ddd' }}>
        <Query query={getContentQuery} variables={{ name: 'agbs' }}>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return null;
            if (!data || !data.allygnContent) return null;

            return (
              <div className="container allygn-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-8">
                    <p dangerouslySetInnerHTML={{ __html: data.allygnContent.text }} />
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
      </section>
      <section className="LegalSection" id="privacy" style={{ backgroundColor: '#eee' }}>
        <Query query={getContentQuery} variables={{ name: 'privacy' }}>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return null;
            if (!data || !data.allygnContent) return null;

            return (
              <div className="container allygn-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-8">
                    <p dangerouslySetInnerHTML={{ __html: data.allygnContent.text }} />
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
      </section>
      <section className="LegalSection" id="imprint" style={{ backgroundColor: '#ddd' }}>
        <Query query={getContentQuery} variables={{ name: 'imprint' }}>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return null;
            if (!data || !data.allygnContent) return null;

            return (
              <div className="container allygn-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-8">
                    <p dangerouslySetInnerHTML={{ __html: data.allygnContent.text }} />
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
      </section>
      <Footer onLegal />
    </>
  );
}

export default LegalsPage;

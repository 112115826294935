import React from 'react';
import Product from '../containers/Product';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import { productRoute } from '../routes';

function ProductPage({ match, history }) {
  const replaceSlug = (slug) => history.replace(productRoute(slug));

  return (
    <>
      <Nav showImmediately />
      <Product slug={match.params.slug} replaceSlug={replaceSlug} />
      <Footer />
    </>
  );
}

export default ProductPage;

import React from 'react';
import { Query } from 'react-apollo';
import { getContentQuery } from '../queries';
import './Contact.sass';

function Contact() {
  return (
    <section id="contact" className="Contact">
      <Query query={getContentQuery} variables={{ name: 'contact' }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return null;
          if (!data || !data.allygnContent) return null;

          return (
            <div className="container allygn-container">
              <div className="row">
                <div className="col-xs-12">
                  <p dangerouslySetInnerHTML={{ __html: data.allygnContent.text }} />
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    </section>
  );
}

export default Contact;

import React from 'react';
import { Query } from 'react-apollo';
import ProductSkeleton from './ProductSkeleton';
import { getProductQuery } from '../queries';
import Product from './Product';
import Page404 from '../../components/Page404';

function ProductQuery({ slug, replaceSlug }) {
  return (
    <Query query={getProductQuery} variables={{ slug }}>
      {({ loading, error, data }) => {
        if (loading) return <ProductSkeleton />;
        if (error) return <Page404 />;
        const { allygnProduct } = data;
        if (!allygnProduct) return <Page404 />;

        return <Product allygnProduct={allygnProduct} />;
      }}
    </Query>
  );
}

export default ProductQuery;

import React from 'react';
import { Parallax } from 'react-parallax';
import { NavHashLink as Link } from 'react-router-hash-link';
// import headerXs from '../images/header-xs.jpg';
import headerLg from '../images/header-lg.jpg';
import logo from '../images/allygn-start-logo-white.svg';
import './Header.sass';

function Header() {
  return (
    <header id="header">
      <Parallax bgImage={headerLg} strength={420}>
        <div style={{ height: '100vh' }}>
          <div id="allygn-logo">
            <img src={logo} alt="allygn-logo" />
          </div>
          <Link
            smooth={true}
            to="#products"
            data-target="#products"
            className="js-scroll-trigger to-products"
          />
        </div>
      </Parallax>
    </header>
  );
}

export default Header;

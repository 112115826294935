import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ children, location: { pathname }, history }) => {
  useEffect(() => {
    if (history.action === 'PUSH' && pathname !== '/' && pathname !== '/legal') window.scrollTo(0, 0);
  }, [pathname, history.action]);

  return children || null;
};

export default withRouter(ScrollToTop);

import React from 'react';
import TrackVisibility from 'react-on-screen';
import Feature from './Feature';
import './Features.sass';

function Features({ title, features }) {
  if (features.length === 0) return null;

  return (
    <div className="Features container">
      <div className="row">
        <h2>{`${title} Features`}</h2>
      </div>
      {features.map(f => (
        <TrackVisibility key={f.id} once partialVisibility offset={250}>
          <Feature feature={f} />
        </TrackVisibility>
      ))}
    </div>
  );
}

export default Features;

import React from 'react';
import ProductsQuery from './ProductsQuery';
import './Products.sass';

function Products() {
  return (
    <section id="products" className="Products bg-light">
      <div className="container-fluid allygn-container">
        <h1>A framebuilder owned component company</h1>
        <ProductsQuery />
      </div>
    </section>
  )
}

export default Products;

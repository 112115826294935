import React, { useState } from 'react';
import Lightbox from 'react-images';
import BuyButton from './BuyButton';
import './Description.sass';

function Description({ allygnProduct }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const openLightbox = index => {
    setCurrentImage(index);
    setLightboxOpen(true);
  }

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxOpen(false);
  }

  const gotoPrevious = () => setCurrentImage(currentImage - 1);
  const gotoNext = () => setCurrentImage(currentImage + 1);

  return (
    <div className="Description container">
      <div className="row">
        <div className="col-sm-12">
          <h1>{allygnProduct.title}</h1>
        </div>
        {allygnProduct.images &&
          <div className="col-xs-12 col-md-6 images">
            {allygnProduct.images.length > 0 &&
              <img
                className="main-image"
                src={`${allygnProduct.images[0].url}?size=md`}
                alt={`${allygnProduct.slug}-0`}
                onClick={() => openLightbox(0)}
              />
            }
            <div className="row thumbs">
              {allygnProduct.images.map((img, index) => {
                if (index < 1) return null;
                return (
                  <div key={img.id} className="thumb col-xs-6 col-sm-4 col-md-3">
                    <img
                      className="image"
                      src={`${img.url}?size=md`}
                      alt={`${allygnProduct.slug}-${index}`}
                      onClick={() => openLightbox(index)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        }
        <div className="col-xs-12 col-md-6">
          <div
            className="description-text"
            dangerouslySetInnerHTML={{ __html: allygnProduct.description }}
          />
          <span className="price">
            {allygnProduct.price && <h2>{allygnProduct.price}</h2>}
          </span>
          <BuyButton
            shopUrl={allygnProduct.shopUrl}
            comingSoon={allygnProduct.comingSoon}
          />
        </div>
      </div>
      {allygnProduct.images && allygnProduct.images.length > 0 &&
        <Lightbox
          images={allygnProduct.images.map(i => { return { ...i, src: `${i.url}?size=original` }; })}
          onClose={closeLightbox}
          onClickPrev={gotoPrevious}
          onClickNext={gotoNext}
          currentImage={currentImage}
          isOpen={lightboxOpen}
          imageCountSeparator=" von "
          showThumbnails={false}
          backdropClosesModal={true}
        />
      }
    </div>
  );
}

export default Description;

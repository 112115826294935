import React from 'react';
import './Page404.sass';

function Page404() {
  return (
    <div className="page-404 container allygn-container">
      <h1>Seite nicht gefunden</h1>
    </div>
  );
}

export default Page404;

import React from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import './ProductItem.sass';

function ProductItem({ product }) {
  const { title, subtitle, slug, firstImageUrl, comingSoon } = product;

  return (
    <div className="ProductItem col-xs-12 col-sm-6 col-md-4">
      <div className="wrapper">
        <Link to={`/products/${slug}`} className="direction-reveal__card">
          <div className="image-wrapper" style={{ backgroundImage: `${firstImageUrl}?size=blur` }}>
            <LazyLoad once height="100%">
              <img
                className="item-image"
                src={`${firstImageUrl}?size=md`}
                alt={slug}
              />
            </LazyLoad>
          </div>
          <div className="direction-reveal__overlay direction-reveal__anim--out">
            <div className="content">
              <h2>{title}</h2>
              <div className="separator"></div>
              <h3>{comingSoon ? 'COMING SOON' : subtitle}</h3>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default React.memo(ProductItem);
